import axios from "axios";

// Default API
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,

  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  },
});

// Payment API
const paymentApi = axios.create({
  baseURL: process.env.REACT_APP_API_PAYMENT_BASE_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_PAYMENT_API_KEY,
  },
});

// Payment API
const callbackApi = axios.create({
  baseURL: process.env.REACT_APP_API_CALLBACK_BASE_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_CALLBACK_API_KEY,
  },
});

export default api;

export { paymentApi, callbackApi };
