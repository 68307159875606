import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/api";

// Async thunk for fetching all categories
export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get("/categories/all_categories", {
        withCredentials: true,
      });
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const homeReducer = createSlice({
  name: "home",
  initialState: {
    categories: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchCategories.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.categories = payload;
      });
  },
});

export default homeReducer.reducer;
