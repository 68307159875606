import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/productDetails";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Cart from "./pages/Cart";
import CheckOut from "./pages/CheckOut";
import ThankYou from "./pages/ThankYou";
import VerifyPayment from "./pages/VerifyPayment";
import OrderHistory from "./pages/OrderHistory";
import Profile from "./pages/Profile";
import TrackOrders from "./pages/TrackOrders";
import ShippingAddress from "./pages/ShippingAddress";
import Support from "./pages/Support";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/productDetails/:productId" element={<ProductDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/thank-you" element={<ThankYou />}></Route>
        <Route path="/orderhistory" element={<OrderHistory />}></Route>
        <Route path="/profile" element={<Profile />} />
        <Route path="/trackorders" element={<TrackOrders />} />
        <Route path="/shippingaddress" element={<ShippingAddress />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/verify_market_place_payment"
          element={<VerifyPayment />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
