import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import InputField from "./inputField";
import zippyLogo from "../assets/images/zippylogo.png";
import { IoIosArrowDown } from "react-icons/io";
import { RiShoppingCart2Line, RiUser3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartItems,
  setCartProductsCount,
  resetCartCount,
} from "../store/reducers/cartReducer";
import { logout } from "../store/reducers/authReducer";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { cart_products_count, cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const redirect_cart_page = () => {
    if (userInfo) {
      navigate("/cart");
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchCartItems(userInfo.userId));
    }
  }, [dispatch, userInfo]);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  // Logout handler
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetCartCount());

    navigate("/"); // Redirect to home after logging out
  };

  return (
    <div className="border-b border-black/10">
      <div className="grid grid-cols-3 py-4 mx-4 sm:mx-10">
        <Link to="/">
          <div className="flex items-center justify-start">
            <img src={zippyLogo} alt="ZippyWorld" />
            <span className="text-lg font-bold text-blue ms-4 font-baskerVille">
              Marketplace.
            </span>
          </div>
        </Link>

        <div className="justify-center hidden sm:flex gap-x-2">
          <InputField
            inputType={"text"}
            placeholder={"Search marketplace..."}
          />
          <Button buttonName={"Search"} buttonType={"button"} />
        </div>

        <div className="justify-end hidden sm:flex">
          <div className="relative flex items-center mr-4">
            {/* User Icon */}
            <span className="text-blue">
              <RiUser3Line size={20} />
            </span>

            {/* Welcome Text */}
            <span className="text-blue font-semibold text-[16px] mx-2">
              {userInfo
                ? `Welcome, ${
                    userInfo.username.charAt(0).toUpperCase() +
                    userInfo.username.slice(1)
                  }`
                : "Welcome, Guest"}
            </span>

            {userInfo ? (
              <div className="relative flex items-center cursor-pointer">
                {/* Dropdown Button */}
                <span
                  className="cursor-pointer text-blue"
                  onClick={toggleDropdown}
                >
                  <IoIosArrowDown size={20} />
                </span>

                {/* Dropdown Menu */}
                {dropdownVisible && (
                  <div className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-7">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-left text-black hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <span
                  className="cursor-pointer text-blue"
                  onClick={toggleDropdown}
                >
                  <IoIosArrowDown size={20} />
                </span>

                {/* Login/Sign Up Dropdown */}
                {dropdownVisible && (
                  <div className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-7">
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="block px-4 py-2 text-black hover:bg-gray-100"
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Cart Icon and Count */}
          <div className="flex items-center justify-center ml-4">
            <span
              className="flex items-center cursor-pointer text-blue"
              onClick={redirect_cart_page}
            >
              <RiShoppingCart2Line size={24} aria-hidden="true" />

              {cart_products_count > 0 || cartItems.length > 0 ? (
                <div className="flex items-center justify-center w-4 h-4 p-2 rounded-full text-blue">
                  {cart_products_count || cartItems.length}
                </div>
              ) : (
                <div className="flex items-center justify-center w-4 h-4 p-2 rounded-full text-blue">
                  0
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
