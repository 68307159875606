import React from "react";
import AccountSideBar from "../components/accountSideBar";
import TopBar from "../components/TopBar";

const Support = () => {
  return (
    <div>
      <TopBar />
      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 p-8">
        <AccountSideBar />
        <main className="grid col-span-12 md:col-span-9 overflow-x-hidden overflow-y-auto border border-borderLine rounded-lg py-4 px-6 bg-white font-baskerVille h-[400px]">
          {/* Page Content Goes Here */}
          <h2 className="font-bold">Help and support</h2>
          <div className="flex justify-center">
            <p className="text-xl font-semibold text-ashColor">
              Submit a problem and show contact details
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Support;
