import React from "react";
import TopBar from "../components/TopBar";
import AccountSideBar from "../components/accountSideBar";
import InputField from "../components/inputField";
import Button from "../components/Button";

const TrackOrders = () => {
  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12">
        {/* Aside Section */}
        <AccountSideBar />

        {/* Main Content Area */}
        <main className="grid col-span-12 p-3 overflow-x-hidden overflow-y-auto bg-white border rounded-lg md:col-span-9 md:py-4 md:px-6 border-borderLine">
          {/* Page Content Goes Here */}
          <div className="mb-8">
            <div>
              <h2 className="mb-6 text-lg font-bold font-baskerVille">
                Track order
              </h2>

              <p className="mb-2">Order ID:</p>
              <div className="w-1/2 gap-2 md:flex md:items-center">
                <div className="mb-2 shrink grow md:mb-0">
                  <InputField inputType={"text"} placeholder={"e.g. fre419"} />
                </div>
                <Button buttonName={"Track Order"} buttonType={"button"} />
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <span className="font-bold font-baskerVille">Order details:</span>
          </div>

          <div className="flex gap-4">
            <div className="w-full">
              <div className="p-2">
                <p className="mb-2">Order date:</p>
                <p className="text-ashColor">23rd January, 2099</p>
              </div>

              <div className="p-2">
                <p className="mb-2">Order amount:</p>
                <p className="text-ashColor">75,500 naira (2 items)</p>
              </div>
            </div>

            <div className="w-full">
              <div className="p-2">
                <p className="mb-4">Status:</p>

                <div className="flex items-center mb-4 gap-x-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#127EC8" />
                    </svg>
                  </span>
                  <div className="text-sm">
                    <span className="inline-block mr-1">Ordered confirmed</span>
                    <span className="text-ashColor">23rd January, 2099</span>
                  </div>
                </div>

                <div className="flex items-center mb-4 gap-x-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#F5F5F5" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="#C4C4C4"
                        strokeOpacity="0.32"
                      />
                    </svg>
                  </span>
                  <div className="text-sm">
                    <span className="inline-block mr-1">Shipped</span>
                    <span className="text-ashColor">23rd January, 2099</span>
                  </div>
                </div>
                <div className="flex items-center mb-4 gap-x-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#F5F5F5" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="#C4C4C4"
                        strokeOpacity="0.32"
                      />
                    </svg>
                  </span>
                  <div className="text-sm">
                    <span className="inline-block mr-1">Delivered</span>
                    <span className="text-ashColor">23rd January, 2099</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TrackOrders;
