import { Link } from "react-router-dom";

const StoreCategories = ({ storeName, storeIcon, storeLink }) => {
  return (
    <div className="">
      <Link
        to={storeLink}
        className="flex items-center gap-x-4 mb-7 hover:scale-95"
      >
        <span>{storeIcon}</span>
        <span className="text-base font-medium">{storeName}</span>
      </Link>
    </div>
  );
};

export default StoreCategories;
