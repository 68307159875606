import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

const ThankYou = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-8 bg-gray-100">
      <h1 className="mb-4 text-4xl font-bold text-green-600">Thank You!</h1>
      <p className="mb-8 text-lg text-gray-700">
        You have successfully purchased an item. We appreciate your business!
      </p>
      <Link to="/">
        <Button buttonType="button" buttonName="Go to Homepage" />
      </Link>
    </div>
  );
};

export default ThankYou;
