import { useSearchParams } from "react-router-dom";

const VerifyPayment = () => {
  const [searchParams] = useSearchParams();

  const transactionId = searchParams.get("transactionId");
  const customerId = searchParams.get("customerId");
  const orderId = searchParams.get("orderId");

  return (
    <div>
      <h1>Verify Payment</h1>
      <p>Transaction ID: {transactionId}</p>
      <p>Customer ID: {customerId}</p>
      <p>Order ID: {orderId}</p>
    </div>
  );
};

export default VerifyPayment;
