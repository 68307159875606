import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountSideBar from "../components/accountSideBar";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import { FaEdit } from "react-icons/fa";
import {
  fetchShippingAddress,
  deleteShippingAddress,
} from "../store/reducers/shippingReducer"; // Adjust the path as necessary

const ShippingAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch shipping addresses from the Redux store
  const { shippingAddresses, loader } = useSelector((state) => state.shipping);

  // Retrieve userId from sessionStorage
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || {};
  const userId = userInfo.userId;

  const handleEdit = (address) => {
    // Assuming you have a route to handle address editing
    navigate(`/edit-shipping-address/${address.id}`, { state: { address } });
  };

  const handleDelete = (addressId) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      dispatch(deleteShippingAddress(addressId));
    }
    dispatch(fetchShippingAddress(userId));
  };

  useEffect(() => {
    if (userId) {
      // Dispatch the action to fetch shipping addresses when component mounts
      dispatch(fetchShippingAddress(userId));
    }
  }, [dispatch, userId]);

  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12">
        <AccountSideBar />
        <main className="grid col-span-12 px-6 py-5 overflow-x-hidden overflow-y-auto bg-white border rounded-lg md:col-span-9 border-borderLine">
          <div className="mb-8">
            <h2 className="mb-6 text-lg font-bold font-baskerVille">
              Shipping address
            </h2>
            <div className="w-4/5 md:w-1/4">
              <Button
                buttonName={"Add new shipping address"}
                buttonType={"button"}
              />
            </div>
          </div>

          {/* Loader state */}
          {loader && <p>Loading addresses...</p>}

          {/* Display shipping addresses */}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {shippingAddresses.length > 0 ? (
              shippingAddresses.map((address) => (
                <div
                  key={address.id}
                  className="relative px-3 py-2 mb-2 border rounded-lg border-borderColor"
                >
                  {/* Position edit and delete buttons at the top right corner */}
                  <div className="absolute flex space-x-2 top-2 right-2">
                    <button
                      className="rounded hover:shadow-lg hover:shadow-yellow-500/50"
                      // to={`/seller/dashboard/edit-product/${product.product_id}`}
                      onClick={() => handleEdit(address)}
                    >
                      <FaEdit className="text-blue" />
                    </button>

                    {/* <span> */}
                    <button
                      onClick={() => handleDelete(address.id)}
                      className="rounded hover:shadow-lg hover:shadow-red-500/50"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M2.66634 5.33331H13.333V14C13.333 14.3682 13.0345 14.6666 12.6663 14.6666H3.33301C2.96482 14.6666 2.66634 14.3682 2.66634 14V5.33331ZM4.66634 3.33331V1.99998C4.66634 1.63179 4.96482 1.33331 5.33301 1.33331H10.6663C11.0345 1.33331 11.333 1.63179 11.333 1.99998V3.33331H14.6663V4.66665H1.33301V3.33331H4.66634ZM5.99967 2.66665V3.33331H9.99967V2.66665H5.99967ZM5.99967 7.99998V12H7.33301V7.99998H5.99967ZM8.66634 7.99998V12H9.99967V7.99998H8.66634Z"
                          fill="#F9533C"
                        />
                      </svg>
                    </button>
                    {/* </span> */}
                  </div>
                  <p className="font-semibold">{address.contact_person}</p>
                  <p className="text-[#C4C4C4]">{address.phonenumber}</p>
                  <p className="text-[#C4C4C4]">
                    {address.address}, {address.city}, {address.state},{" "}
                    {address.country}.
                  </p>
                </div>
              ))
            ) : (
              <p>No shipping addresses found.</p>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ShippingAddress;
