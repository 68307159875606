import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomersOrders } from "../store/reducers/paymentReducer";
import seriesX from "../assets/images/productImage/headsetStore.png";
import AccountSideBar from "../components/accountSideBar";
import Button from "../components/Button";
import TopBar from "../components/TopBar";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { customerOrders } = useSelector((state) => state.payment);

  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState("open");

  useEffect(() => {
    dispatch(fetchCustomersOrders(sessionStorage.getItem("userId")));
  }, [dispatch]);

  // Filter orders based on status
  const openOrders = customerOrders.filter(
    (order) => order.order_status === "pending"
  );
  const closedOrders = customerOrders.filter(
    (order) => order.order_status === "completed"
  );

  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12">
        {/* Aside Section */}
        <AccountSideBar />

        {/* Main Section */}
        <main className="grid col-span-12 p-3 overflow-x-hidden overflow-y-auto bg-white border rounded-lg md:col-span-9 md:py-4 md:px-6 border-borderLine">
          {/* Page Content Goes Here */}
          <div className="mb-8">
            <h2 className="mb-6 text-lg font-bold font-baskerVille">
              Order History
            </h2>

            <div className="flex flex-col w-2/3 gap-4 md:flex-row">
              <button
                type="button"
                className={`py-3 px-[30px] text-sm font-normal text-center rounded-[5px] ${
                  activeTab === "open"
                    ? "bg-blue text-white"
                    : "bg-grayColor/20 text-gray-700"
                }`}
                onClick={() => setActiveTab("open")}
              >
                Open Orders
              </button>
              <button
                type="button"
                className={`py-3 px-[30px] text-sm font-normal text-center rounded-[5px] ${
                  activeTab === "closed"
                    ? "bg-blue text-white"
                    : "bg-grayColor/20 text-gray-700"
                }`}
                onClick={() => setActiveTab("closed")}
              >
                Closed Orders
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            {/* Map over orders to display each one based on active tab */}
            {(activeTab === "open" ? openOrders : closedOrders).map((order) => (
              <div
                key={order.order_id}
                className="flex flex-col items-center p-3 mb-2 border rounded-lg border-borderColor md:flex-row md:items-start md:justify-between gap-x-4"
              >
                <div className="bg-ashColor/16 mb-4 md:mb-0 w-full h-[200px] md:w-28 md:h-[100px] flex justify-center items-center rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.0049 13.0029V20.0029C20.0049 20.5552 19.5572 21.0029 19.0049 21.0029H5.00488C4.4526 21.0029 4.00488 20.5552 4.00488 20.0029V13.0029H20.0049ZM14.5049 2.00293C16.4379 2.00293 18.0049 3.56993 18.0049 5.50293C18.0049 6.04014 17.8839 6.54908 17.6676 7.00397L21.0049 7.00293C21.5572 7.00293 22.0049 7.45064 22.0049 8.00293V11.0029C22.0049 11.5552 21.5572 12.0029 21.0049 12.0029H3.00488C2.4526 12.0029 2.00488 11.5552 2.00488 11.0029V8.00293C2.00488 7.45064 2.4526 7.00293 3.00488 7.00293L6.34219 7.00397C6.12591 6.54908 6.00488 6.04014 6.00488 5.50293C6.00488 3.56993 7.57189 2.00293 9.50488 2.00293C10.4849 2.00293 11.3708 2.40569 12.0061 3.05471C12.639 2.40569 13.5249 2.00293 14.5049 2.00293ZM9.50488 4.00293C8.67646 4.00293 8.00488 4.6745 8.00488 5.50293C8.00488 6.28263 8.59977 6.92338 9.36042 6.99606L9.50488 7.00293H11.0049V5.50293C11.0049 4.72323 10.41 4.08248 9.64934 4.0098L9.50488 4.00293ZM14.5049 4.00293L14.3604 4.0098C13.6473 4.07794 13.0799 4.64536 13.0117 5.35847L13.0049 5.50293V7.00293H14.5049L14.6493 6.99606C15.41 6.92338 16.0049 6.28263 16.0049 5.50293C16.0049 4.72323 15.41 4.08248 14.6493 4.0098L14.5049 4.00293Z"
                      fill="#127EC8"
                    />
                  </svg>
                </div>

                <div className="flex flex-col justify-between w-full md:flex-row">
                  <div className="mb-2">
                    <p className="mb-2">
                      {order.orderItems[0].product_name}{" "}
                      <span className="text-ashColor">
                        (+ {order.order_items_count - 1} items)
                      </span>
                    </p>
                    <div className="gap-6 mb-2 md:flex">
                      <p>
                        Order ID:{" "}
                        <span className="text-ashColor">{order.order_id}</span>
                      </p>
                      <p>
                        Order date:{" "}
                        <span className="text-ashColor">
                          {new Date(
                            order.orderItems[0].ordered_at
                          ).toLocaleDateString()}
                        </span>
                      </p>
                    </div>
                    <p>
                      Status:
                      <button
                        className={`px-2 py-1 ml-1 text-sm rounded-sm ${
                          order.order_status === "completed"
                            ? "bg-black/16"
                            : "bg-yellow/16"
                        }`}
                      >
                        {order.order_status}
                      </button>
                    </p>
                  </div>
                  <div>
                    <Link to={`/order/${order.order_id}`} className="text-blue">
                      See details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default OrderHistory;
