import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { paymentApi, callbackApi } from "../../api/api";

export const sendOTP = createAsyncThunk(
  "payment/sendOTP",
  async (
    { zippy_wallet_number, pin, name, email, security_answer, service_amount },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/payment/send_otp", {
        zippy_wallet_number,
        pin,
        name,
        email,
        security_answer,
        service_amount,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const debitWallet = createAsyncThunk(
  "payment/debitWallet",
  async (
    {
      zippy_wallet_number,
      pin,
      name,
      email,
      security_answer,
      service_amount,
      otp,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/payment/debit_wallet", {
        zippy_wallet_number,
        pin,
        name,
        email,
        security_answer,
        service_amount,
        otp,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const generateVirtualAccount = createAsyncThunk(
  "payment/generateVirtualAccount",
  async (accountData, { rejectWithValue }) => {
    try {
      const response = await paymentApi.post("/create_account", accountData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const monnifyProcessPayment = createAsyncThunk(
  "payment/monnifyProcessPayment",
  async ({ amount, customer_id, description }, { rejectWithValue }) => {
    try {
      const response = await callbackApi.post("/monnify_payment_initialize", {
        amount,
        customer_id,
        description,
      });

      if (response.data.status_code === "0") {
        window.location.href = response.data.result.checkOutUrl;
      } else {
        return rejectWithValue({
          message: response.data.message,
          amountEmpty: !amount,
          customerIdEmpty: !customer_id,
        });
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createOrder = createAsyncThunk(
  "payment/createOrder",
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await api.post("/orders/create_order", orderData);
      return response.data.status_code === 0
        ? response.data.orderDetails
        : rejectWithValue({ message: response.data.message });
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchCustomersOrders = createAsyncThunk(
  "payment/fetchCustomersOrders",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/orders/customer_orders/${customerId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Async thunk for checking payment status
export const checkTransferPaymentStatus = createAsyncThunk(
  "payment/checkTransferPaymentStatus",

  async ({ transactionId, customerId }, { rejectWithValue }) => {
    try {
      const response = await callbackApi.get(
        `/verify_market_place_payment?transactionId=${transactionId}&customerId=${customerId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const paymentReducer = createSlice({
  name: "payment",
  initialState: {
    loader: false,
    errorMessage: "",
    successMessage: "",
    error: "",
    success: "",
    debitSuccess: "",
    debitError: "",
    paymentSuccess: "",
    virtualAccount: "",
    virtualAccountName: "",
    customerOrders: [],
    checkTransferPaymentStatusLoader: false,
    paymentInterval: null,
  },
  reducers: {
    resetPaymentState: (state) => {
      state.error = "";
      state.success = "";
      state.debitError = "";
      state.debitSuccess = "";
      state.paymentSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOTP.pending, (state) => {
        state.loader = true;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loader = false;
        state.otpSent = true;
        state.success = action.payload.message.message;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload?.message.message || "An error occurred";
      })
      .addCase(debitWallet.pending, (state) => {
        state.loader = true;
      })
      .addCase(debitWallet.fulfilled, (state) => {
        state.loader = false;
        state.paymentSuccess = true;
        state.debitSuccess = "Payment has been made successfully";
      })
      .addCase(debitWallet.rejected, (state, action) => {
        state.loader = false;
        state.debitError = action.payload?.message || "An error occurred";
      })
      .addCase(createOrder.pending, (state) => {
        state.loader = true;
      })
      .addCase(createOrder.fulfilled, (state) => {
        state.loader = false;
        state.successMessage = "Order created successfully";
        window.location.href = "/orderhistory";
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload?.message || "Failed to create order";
      })
      .addCase(fetchCustomersOrders.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchCustomersOrders.fulfilled, (state, action) => {
        state.loader = false;
        state.customerOrders = action.payload.orders;
      })
      .addCase(fetchCustomersOrders.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage =
          action.payload?.message || "Failed to fetch orders";
      })
      .addCase(generateVirtualAccount.pending, (state) => {
        state.loader = true;
      })
      .addCase(generateVirtualAccount.fulfilled, (state, action) => {
        state.loader = false;
        state.virtualAccount = action.payload.data.accountNumber;
        state.virtualAccountName = action.payload.data.accountName;
        state.success = action.payload.message;
      })
      .addCase(generateVirtualAccount.rejected, (state, action) => {
        state.loader = false;
        state.error =
          action.payload?.message || "Failed to create virtual account";
      })
      .addCase(monnifyProcessPayment.pending, (state) => {
        state.loader = true;
      })
      .addCase(monnifyProcessPayment.fulfilled, (state) => {
        state.loader = false;
        state.successMessage = "Payment processed successfully";
      })
      .addCase(monnifyProcessPayment.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload?.message || "An error occurred";
      })
      .addCase(checkTransferPaymentStatus.pending, (state) => {
        state.checkTransferPaymentStatusLoader = true;
      })
      .addCase(checkTransferPaymentStatus.fulfilled, (state, action) => {
        state.checkTransferPaymentStatusLoader = false;
        if (action.payload.status_code === "0") {
          state.successMessage = "Payment verified successfully!";
          // clearInterval(state.paymentInterval);
        } else {
          state.errorMessage = action.payload.message;
        }
      })
      .addCase(checkTransferPaymentStatus.rejected, (state, action) => {
        state.checkTransferPaymentStatusLoader = false;
        state.errorMessage =
          action.payload?.message || "Failed to verify payment";
      });
  },
});

export const { resetPaymentState } = paymentReducer.actions;
export default paymentReducer.reducer;
