import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccountSideBar from "../components/accountSideBar";
import TopBar from "../components/TopBar";
import { fetchShippingAddress } from "../store/reducers/shippingReducer"; // Import the fetchShippingAddress action

const Profile = () => {
  const dispatch = useDispatch();

  const { shippingAddresses } = useSelector((state) => state.shipping);

  // Assuming you have userInfo stored in Redux or localStorage
  const userId = sessionStorage.getItem("userId"); // Or fetch from your user state

  useEffect(() => {
    if (userId) {
      dispatch(fetchShippingAddress(userId));
    }
  }, [dispatch, userId]);

  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12">
        {/* Aside Section */}
        <AccountSideBar />

        {/* Main Content Area */}
        <main className="grid col-span-12 p-4 overflow-x-hidden overflow-y-auto bg-white border rounded-lg md:col-span-9 border-borderLine md:py-4 md:px-6">
          {/* Page Content Goes Here */}
          <div className="flex items-center mb-8 gap-x-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
              >
                <circle cx="40" cy="40" r="40" fill="#D9D9D9" />
              </svg>
            </span>
            <div className="font-nunito">
              <p className="font-bold">{sessionStorage.getItem("username")}.</p>
              <p className="text-sm font-medium text-ashColor">
                {sessionStorage.getItem("phonenumber")}
              </p>
            </div>
          </div>
          <div className="flex justify-between mb-6 text-sm md:text-base">
            <span className="font-bold font-baskerVille">
              Shipping address:
            </span>
            <Link to={""} className="font-medium text-blue">
              Manage addresses
            </Link>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {/* Loop through shippingAddresses to display each address */}
            {shippingAddresses.map((address) => (
              <div
                key={address.id}
                className="p-2 mb-2 border rounded-lg border-borderColor"
              >
                <p className="font-semibold">{address.contact_person}</p>
                <p className="text-ashColor">{address.phonenumber}</p>
                <p className="text-ashColor">
                  {`${address.address}, ${address.city}, ${address.state}, ${address.country}.`}
                </p>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Profile;
