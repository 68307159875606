export const transformGoogleDriveLink = (pictureObject) => {
  if (
    typeof pictureObject === "object" &&
    pictureObject !== null &&
    pictureObject.picture1
  ) {
    const url = pictureObject.picture1;
    const match = url.match(/\/d\/(.*?)\//);
    if (match && match[1]) {
      return `https://drive.google.com/thumbnail?id=${match[1]}`;
    }
    return url; // Return the original URL if it doesn't match the pattern
  }
  return pictureObject || "https://via.placeholder.com/640x480.png"; // Return a placeholder if it's null or undefined
};
