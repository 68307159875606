import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShippingAddress,
  messageClear,
} from "../store/reducers/shippingReducer";
import { fetchCartItems } from "../store/reducers/cartReducer";
import {
  sendOTP,
  debitWallet,
  createOrder,
  generateVirtualAccount,
  resetPaymentState,
  monnifyProcessPayment,
  checkTransferPaymentStatus,
} from "../store/reducers/paymentReducer";
import TopBar from "../components/TopBar";
import Button from "../components/Button";
import ShippingAddressModal from "./ShippingAddressModal";
import toast from "react-hot-toast";

const CheckOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorMessage, successMessage, shippingAddresses } = useSelector(
    (state) => state.shipping
  );

  const {
    error,
    success,
    debitSuccess,
    debitError,
    otpSent,
    loader,
    virtualAccount,
    virtualAccountName,
  } = useSelector((state) => state.payment);

  const { userInfo } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);

  const subtotal = useSelector((state) => state.cart.subtotal);
  const formattedSubtotal = subtotal.toLocaleString();

  const [isZippyWalletSelected, setIsZippyWalletSelected] = useState(false);
  const [isBankTransferSelected, setBankTransferSelected] = useState(false);
  const [isCardPaymentSelected, setCardPaymentSelected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zippyWalletNumber, setZippyWalletNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [otp, setOtp] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [callbackIntervalId, setCallbackIntervalId] = useState(null);
  const [timer, setTimer] = useState(600); // 10 minutes = 600 seconds

  const toggleZippyWalletFields = () => {
    setIsZippyWalletSelected((prev) => !prev);
    setBankTransferSelected(false);
    setCardPaymentSelected(false);
  };

  const toggleBankTransferFields = () => {
    setBankTransferSelected((prev) => !prev);
    setIsZippyWalletSelected(false);
    setCardPaymentSelected(false);
  };

  const toggleCardPaymentFields = () => {
    setCardPaymentSelected((prev) => !prev);
    setIsZippyWalletSelected(false);
    setBankTransferSelected(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSendOTP = (e) => {
    e.preventDefault();

    const paymentData = {
      zippy_wallet_number: zippyWalletNumber,
      name: name,
      email: email,
      pin: pin,
      security_answer: securityAnswer,
      service_amount: subtotal,
    };

    dispatch(sendOTP(paymentData));
  };

  const prepareOrderData = async () => {
    try {
      // Dispatch the action and wait for it to resolve
      const action = await dispatch(fetchCartItems(userInfo.userId));

      // Access the payload from the resolved action
      const cartItems = action.payload;

      // Map the cart items to order_items array
      const orderItems = cartItems.map((item) => ({
        product_id: item.product_id,
        merchant_id: item.merchant_id,
        price: item.product_price,
      }));

      // Prepare the data object
      const data = {
        customer_id: userInfo.userId,
        vat: "10.00",
        delivery_fee: "5.00",
        order_items: orderItems,
      };

      // Set the order data to state
      setOrderData(data);

      return data;
    } catch (error) {
      console.error("Error preparing order data:", error);
      // Handle the error appropriately
    }
  };

  const handleSuccessfulDebit = async () => {
    try {
      // Prepare the order data
      const orderData = await prepareOrderData();

      // Dispatch the createOrder action with the prepared order data
      const result = await dispatch(createOrder(orderData));

      if (result.payload) {
        // If order creation is successful, navigate to the Thank You page
        navigate("/thank-you");
      }
    } catch (error) {
      console.error("Error handling successful debit:", error);
      toast.error("Failed to create order.");
    }
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    const paymentData = {
      zippy_wallet_number: zippyWalletNumber,
      name: name,
      email: email,
      pin: pin,
      security_answer: securityAnswer,
      service_amount: subtotal,
      otp: otp,
    };

    dispatch(debitWallet(paymentData));
  };

  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const formattedDateTime = `${day}${month}${hours}${minutes}`;

  const accountData = {
    customerName: userInfo.username,
    phoneNumber: formattedDateTime + "" + userInfo.phonenumber.slice(-8),
    mainPhoneNumber: "01000000240",
  };

  const td = accountData.phoneNumber;

  const handleGenerateVirtualAccount = () => {
    dispatch(generateVirtualAccount(accountData));
  };

  const handleMonnifyPayment = async (e) => {
    e.preventDefault();

    const paymentData = {
      customer_id: userInfo.phonenumber,
      amount: subtotal,
      description: "Zippy Marketplace",
    };

    const result = await dispatch(monnifyProcessPayment(paymentData)).unwrap();
  };

  useEffect(() => {
    dispatch(fetchShippingAddress(userInfo.userId));
  }, [dispatch, userInfo.userId]);

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      dispatch(resetPaymentState());
    }
    if (error) {
      toast.error(error);
      dispatch(resetPaymentState());
    }
  }, [success, error, dispatch]);

  const handleCheckPaymentStatus = () => {
    dispatch(
      checkTransferPaymentStatus({
        transactionId: td,
        customerId: userInfo.phonenumber,
      })
    )
      .then((response) => {
        if (response.payload.status_code === "0") {
          toast.success("Payment Successful");
          handleSuccessfulDebit(); // Create the order and proceed
        } else {
          toast.error("Payment not confirmed yet. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error in checking payment status:", error);
        toast.error("An error occurred while checking payment status.");
      });
  };

  useEffect(() => {
    if (debitSuccess) {
      toast.success(debitSuccess);
      handleSuccessfulDebit();
      dispatch(resetPaymentState());
    }
    if (debitError) {
      toast.error(debitError);
      dispatch(resetPaymentState());
    }
  }, [debitSuccess, debitError, dispatch]);

  const renderAddress = () => {
    if (shippingAddresses && shippingAddresses.length > 0) {
      const lastAddress = shippingAddresses[shippingAddresses.length - 1];
      return (
        <div key={lastAddress.id}>
          <p>{lastAddress.contact_person}</p>
          <p>{lastAddress.phonenumber}</p>
          <p>{`${lastAddress.address}, ${lastAddress.city}, ${lastAddress.state}, ${lastAddress.country}`}</p>
        </div>
      );
    } else {
      return <p>No shipping address available.</p>;
    }
  };

  return (
    <div>
      <TopBar />
      <div className="grid grid-cols-1 gap-4 p-8 md:grid-cols-12 font-nunito">
        {/* Shopping Cart Items (col-span-8) */}
        <main className="grid col-span-12 gap-4 md:col-span-8">
          {/* Shopping Cart Header */}
          <div className="p-6 bg-white rounded">
            <h2 className="mb-4 text-xl font-bold font-baskerVille">
              Shopping Cart
            </h2>
            <div className="flex justify-between">
              <div className="font-semibold rounded-lg">{renderAddress()}</div>
              <Link href="#" className="self-end text-blue" onClick={openModal}>
                Change
              </Link>
            </div>
          </div>

          {/* Form */}
          <div className="px-6 py-8 bg-white rounded">
            <h2 className="mb-4 text-xl font-bold font-baskerVille">
              Payment options
            </h2>

            {/* Pay with Zippy Wallet */}
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="payment_option"
                id="zippy_wallet"
                value="zippy_wallet"
                checked={isZippyWalletSelected}
                onChange={toggleZippyWalletFields}
              />
              <label htmlFor="zippy_wallet" className="ml-2">
                Pay with Zippy Wallet :
              </label>
            </div>

            {/* Zippy Wallet Fields */}
            <form onSubmit={otpSent ? handleSubmitOtp : handleSendOTP}>
              <div
                id="zippy_wallet_fields"
                className={isZippyWalletSelected ? "" : "hidden"}
              >
                <div className="mr-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="zippy_wallet_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Zippy Wallet Number:
                  </label>
                  <input
                    type="text"
                    id="zippy_wallet_number"
                    name="zippy_wallet_number"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="08012342419"
                    value={zippyWalletNumber}
                    onChange={(e) => setZippyWalletNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Account Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="Harry Maguire"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="mr-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="harrymaguire@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="pin"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Pin:
                  </label>
                  <input
                    type="password"
                    id="pin"
                    name="pin"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="XXXX"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    required
                  />
                </div>

                <div className="mr-4 md:inline-block md:w-2/5">
                  <label
                    htmlFor="security_answer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Security Answer:
                  </label>
                  <input
                    type="password"
                    id="security_answer"
                    name="security_answer"
                    className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                    placeholder="Security Answer"
                    value={securityAnswer}
                    onChange={(e) => setSecurityAnswer(e.target.value)}
                    required
                  />
                </div>

                {/* OTP Input and Submit Button */}
                {otpSent && (
                  <div className="mb-4 md:inline-block md:w-2/5">
                    <label
                      htmlFor="otp"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter OTP:
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="w-full p-2 mt-1 text-black border rounded outline-none border-ashColor border-opacity-16 bg-grayColor bg-opacity-8"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </div>
                )}

                <div className="my-4">
                  <Button
                    buttonType={"submit"}
                    buttonName={
                      loader
                        ? "Processing..."
                        : otpSent
                        ? "Make Payment"
                        : "Send OTP"
                    }
                    disabled={loader}
                  ></Button>
                </div>
              </div>
            </form>

            {/* Pay with Debit Card */}
            <div className="flex items-center mb-4">
              <input
                type="radio"
                name="payment_option"
                id="debit_card"
                value="debit_card"
                onChange={toggleCardPaymentFields}
              />
              <label htmlFor="debit_card" className="ml-2">
                Pay with Debit Card
              </label>
            </div>

            <div
              id="zippy_wallet_fields"
              className={isCardPaymentSelected ? "" : "hidden"}
            >
              <Button
                buttonType={"submit"}
                buttonName={"Pay With Monnify"}
                onClick={handleMonnifyPayment}
              ></Button>
            </div>

            {/* Pay with Bank Transfer */}
            <div className="flex items-center my-2">
              <input
                type="radio"
                name="payment_option"
                id="bank_transfer"
                value="bank_transfer"
                checked={isBankTransferSelected}
                onChange={toggleBankTransferFields}
              />
              <label htmlFor="bank_transfer" className="ml-2">
                Pay with Bank Transfer
              </label>
            </div>

            {/* Bank Transfer Fields */}
            <div className={isBankTransferSelected ? "" : "hidden"}>
              <button
                type="button"
                className="px-4 py-2 text-white rounded bg-blue"
                onClick={handleGenerateVirtualAccount}
              >
                Generate Virtual Account
              </button>

              {/* Display Virtual Account */}
              {virtualAccount && (
                <div className="mt-4">
                  <p>
                    <strong>Account Number: </strong> {virtualAccount}
                  </p>
                  <p>
                    <strong>Account Name: </strong> {virtualAccountName}
                  </p>
                  <p>
                    <strong>Bank: </strong> Providus bank
                  </p>

                  {/* Transfer Button */}
                  <button
                    type="button"
                    className="px-4 py-2 mt-4 text-white rounded bg-blue"
                    onClick={handleCheckPaymentStatus}
                  >
                    I've done the transfer
                  </button>
                </div>
              )}
            </div>
          </div>
        </main>

        {/* Order Summary */}
        <aside className="col-span-12 p-8 bg-white rounded md:col-span-4 h-max">
          <h2 className="mb-4 text-xl font-bold font-baskerVille">Checkout</h2>

          {/* Subtotal Price */}
          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Subtotal:</span>
            <p className="text-lg">{`₦${formattedSubtotal}`}</p>
          </div>

          {/* Shipping Fee */}
          <div className="flex items-center justify-between mb-4 font-semibold">
            <span>Shipping fee:</span>
            <p className="text-lg">
              <p>Delivery fees not included yet</p>
            </p>
          </div>

          {/* Total Price */}
          <div className="flex items-center justify-between py-4 mb-4 font-semibold border-y border-ashColor border-opacity-32">
            <span>Total:</span>
            <p className="text-lg">{`₦${formattedSubtotal}`}</p>
          </div>
          {/* Checkout Button */}
          <Link to="" className="w-full">
            <Button buttonType={"button"} buttonName={"Checkout"} fullWidth />
          </Link>
        </aside>
      </div>

      {/* Shipping Address Modal */}
      {isModalOpen && <ShippingAddressModal closeModal={closeModal} />}
    </div>
  );
};

export default CheckOut;
